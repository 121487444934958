import { useReducer, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
// import { database } from "../firebase";

const ACTIONS = {
  SELECT_FOLDER: "select-folder",
  UPDATE_FOLDER: "update-folder",
  SET_CHILD_FOLDERS: "set-child-folders",
  SET_CHILD_FILES: "set-child-files",
};

export const ROOT_FOLDER = { name: "Home", id: null, path: [] };

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.SELECT_FOLDER:
      return {
        folderId: payload.folderId,
        folder: payload.folder,
        childFiles: [],
        childFolders: [],
      };
    case ACTIONS.UPDATE_FOLDER:
      return {
        ...state,
        folder: payload.folder,
      };
    case ACTIONS.SET_CHILD_FOLDERS:
      return {
        ...state,
        childFolders: payload.childFolders,
      };
    case ACTIONS.SET_CHILD_FILES:
      return {
        ...state,
        childFiles: payload.childFiles,
      };
    default:
      return state;
  }
}

export function useFolder(folderId = null, folder = null, updated = null) {
  const [state, dispatch] = useReducer(reducer, {
    folderId,
    folder,
    childFolders: [],
    childFiles: [],
  });
  const { currentUser } = useAuth();

  useEffect(() => {
    dispatch({ type: ACTIONS.SELECT_FOLDER, payload: { folderId, folder } });
  }, [folderId, folder]);

  useEffect(() => {
    if (folderId == null) {
      return dispatch({
        type: ACTIONS.UPDATE_FOLDER,
        payload: { folder: ROOT_FOLDER },
      });
    }

    // return current folder
    // [
    //   {
    //     createdAt: t {seconds: 1654846917, nanoseconds: 474000000}
    //     id: "Fu6WCIZsrBRzkgqGuc0W"
    //     name: "Under Root"
    //     parentId: "0H5jM7ekRg4C6pyKjKDd"
    //     path: [{…}]
    //     userId: "ByRiM2V7XIetDB2J2865ICZUgy92"
    //   }
    // ]
    fetch(
      "https://drive.shibpursristi.org/api/api_getFolders.php?reqType=current&folderId=" +
        folderId,
      {}
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        dispatch({
          type: ACTIONS.UPDATE_FOLDER,
          payload: { folder: data },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: ACTIONS.UPDATE_FOLDER,
          payload: { folder: ROOT_FOLDER },
        });
      });
  }, [folderId, updated]);

  // returns child folders
  // [
  //   {
  //     createdAt: t {seconds: 1654846323, nanoseconds: 757000000}
  //     id: "0H5jM7ekRg4C6pyKjKDd"
  //     name: "At Root"
  //     parentId: null
  //     path: []
  //     userId: "ByRiM2V7XIetDB2J2865ICZUgy92"
  //   }
  // ]
  useEffect(() => {
    let folderIdParam = folderId;
    if (folderId === null) {
      folderIdParam = "NULL";
    }
    fetch(
      "https://drive.shibpursristi.org/api/api_getFolders.php?reqType=child&folderId=" +
        folderIdParam,
      {}
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        dispatch({
          type: ACTIONS.SET_CHILD_FOLDERS,
          payload: { childFolders: data },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [folderId, currentUser, updated]);

  // returns child files
  // [
  //   {
  //     createdAt: t {seconds: 1654846814, nanoseconds: 503000000}
  //     folderId: "0H5jM7ekRg4C6pyKjKDd"
  //     id: "TcxLDNX6joMy0VUIiql1"
  //     name: "IMG_20220605_180338.jpg"
  //     url: "https://firebasestorage.googleapis.com/v0/b/shibpur-sristi-cms.appspot.com/o/files%2FByRiM2V7XIetDB2J2865ICZUgy92%2FAt%20Root%2FIMG_20220605_180338.jpg?alt=media&token=5ac88f93-3f0e-4ebc-8602-b68b56d672a8"
  //     userId: "ByRiM2V7XIetDB2J2865ICZUgy
  //   }
  // ]
  useEffect(() => {
    let folderIdParam = folderId;
    if (folderId === null) {
      folderIdParam = "NULL";
    }
    fetch(
      "https://drive.shibpursristi.org/api/api_getFiles.php?folderId=" +
        folderId,
      {}
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        dispatch({
          type: ACTIONS.SET_CHILD_FILES,
          payload: { childFiles: data },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [folderId, currentUser, updated]);

  return state;
}
