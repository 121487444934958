import React, { useState } from "react";
import { Button, Modal, Form, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
// import { database } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { ROOT_FOLDER } from "../../hooks/useFolder";

export default function AddFolderButton({ currentFolder, fileUpdated }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const { currentUser } = useAuth();
  const [toast, setToast] = useState({ status: false, msg: null });

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (currentFolder == null) return;

    const path = [...currentFolder.path];
    if (currentFolder !== ROOT_FOLDER) {
      path.push({ name: currentFolder.name, id: currentFolder.id });
    }

    fetch("https://drive.shibpursristi.org/api/api_addFolder.php", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        name: name,
        parentId: currentFolder.id === "" ? null : currentFolder.id,
        userId: currentUser.email,
        path: path,
      }),
    })
      .then((res) => {
        // console.log(res);
        return res.json();
      })
      .then((data) => {
        if (data.statusCode !== 200) {
          throw new Error(data.msg);
        }
        setToast({ status: true, msg: data.msg });
        fileUpdated();
      })
      .catch((err) => {
        console.log(err);
        setToast({ status: true, msg: err.message });
      });
    setName("");
    closeModal();
  }

  return (
    <>
      <Button onClick={openModal} variant="outline-success" size="sm">
        <FontAwesomeIcon icon={faFolderPlus} />
        Create Folder
      </Button>
      <Modal show={open} onHide={closeModal}>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Folder Name</Form.Label>
              <Form.Control
                type="text"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button variant="success" type="submit">
              Add Folder
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {toast && (
        // <ToastContainer position="top-end">
        <Toast
          onClose={() => setToast({ status: false, msg: null })}
          show={toast.status}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Sristi Drive</strong>
          </Toast.Header>
          <Toast.Body>{toast.msg}</Toast.Body>
        </Toast>
        // {/* </ToastContainer> */}
      )}
    </>
  );
}
