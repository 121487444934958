import React, { useState } from "react";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../contexts/AuthContext";
import { ROOT_FOLDER } from "../../hooks/useFolder";
import { Toast } from "react-bootstrap";

export default function AddFileButton({ currentFolder, fileUpdated }) {
  const [toast, setToast] = useState({ status: false, msg: null });
  const { currentUser } = useAuth();

  function handleUpload(e) {
    console.log(e.target.files);
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      if (currentFolder == null || file == null) return;

      let filePathNames = currentFolder.path.map((item) => item.name);
      const filePath =
        currentFolder === ROOT_FOLDER
          ? `${filePathNames.join("/")}/${file.name}`
          : `/${filePathNames.join("/")}/${currentFolder.name}/${file.name}`;

      // =========================================================
      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "folderId",
        currentFolder.id === "" ? null : currentFolder.id
      );
      formData.append("userId", currentUser.email);
      formData.append("path", filePath);
      // console.log({
      //   file,
      //   folderId: currentFolder.id === "" ? null : currentFolder.id,
      //   userId: currentUser.email,
      //   path: filePath,
      // });
      fetch("https://drive.shibpursristi.org/api/api_addFile.php", {
        headers: {},
        method: "POST",
        body: formData,
      })
        .then((res) => {
          // console.log(res);
          return res.json();
        })
        .then((data) => {
          if (data.statusCode !== 200) {
            throw new Error(data.msg);
          }
          setToast({ status: true, msg: data.msg });
          fileUpdated();
        })
        .catch((err) => {
          console.log(err);
          setToast({ status: true, msg: err.message });
        });
    }
  }

  return (
    <>
      <label className="btn btn-outline-success btn-sm m-0 mr-2">
        <FontAwesomeIcon icon={faFileUpload} />
        Add Files
        <input
          type="file"
          multiple
          onChange={handleUpload}
          style={{ opacity: 0, position: "absolute", left: "-9999px" }}
        />
      </label>

      {toast && (
        <Toast
          onClose={() => setToast({ status: false, msg: null })}
          show={toast.status}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Sristi Drive</strong>
          </Toast.Header>
          <Toast.Body>{toast.msg}</Toast.Body>
        </Toast>
      )}
    </>
  );
}
